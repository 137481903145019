import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useLeaveTeam } from "./useLeaveTeam";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { protoTeamModalState } from "@/windows/editPrototeam/useEditPrototeam";
import { useRecoilValue } from "recoil";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin-bottom: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

const RemoveTeamContainer = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

const ContentContainer = styled.div`

`;

export function LeaveTeam() {
  const selectedTeam = useSelectedTeamState();

  const { isOpen, close, loading, onLeaveTeam } = useLeaveTeam();
  const prototeam = useRecoilValue(protoTeamModalState);

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Leave Team" show={isOpen} onClose={close}>
        <ContentContainer>
          <p>Leaving the {selectedTeam?.title} will remove the team from your account. To rejoin, you will need an invitation code. </p>
          <p>Are you sure you wish to leave?</p>
        </ContentContainer>

        <Divider/>

        <FooterContainer>
          <Button variant="inverted" label="Cancel" onClick={close}>
            Cancel
          </Button>
          <RemoveTeamContainer>
            <Button
              label="Leave Team"
              size="lg"
              width="block"
              backgroundColor="#DC3545"
              shadowColor="#DC3545"
              textColor="#fff"
              onClick={() => {
                if (selectedTeam?.id){
                  onLeaveTeam({ id: selectedTeam?.id });
                } else {
                  console.error("No team selected.")
                }
              }}
            />
          </RemoveTeamContainer>
        </FooterContainer>
      </Modal>
    </ModalContainer>
  );
}
