import { useCallback, useEffect, useState } from "react"
import styled from "styled-components";

import { useAppState } from "@/state/app/useAppState"
import { Menu } from "@gamesheet/ui"
import { useUserState } from "@/state/user/useUserState";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useIpadApiKeys } from "@/windows/ipadApiKeys/useIpadApiKeys";
import { useEditEmailNotifications } from "@/windows/editEmailNotifications/useEditEmailNotifications";
import { useInviteUser } from "@/windows/inviteUser/useInviteUser";
import { useLeaveTeam } from "@/windows/leaveTeam/useLeaveTeam";

import { useFlag } from "@unleash/proxy-client-react";

type MenuContainerProps = {
    $open?: boolean;
}

const MobileMenuContainer = styled.div<MenuContainerProps>`
z-index: 3300;

.more-menu {
    bottom: ${p => p.$open ? '100px' : '-510px'};
    z-index: ${p => p.$open ? '3400' : '900'};
    transition: ${p => p.$open ? 'bottom 1s ease, z-index 1.05s step-end' : 'bottom 1s ease, z-index step-end'};
    padding-bottom:30px;
    background:
    linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 11%,
        rgba(255, 255, 255, 1) 11%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 96%,
      rgba(0, 0, 0, 0) 96%
    ),
    linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 23%,
      rgba(255, 255, 255, 1) 23%,
      rgba(255, 255, 255, 1) 100%
    );
}
`

const DesktopMenuContainer = styled.div<MenuContainerProps>`
z-index: 3300;

.more-menu {
    top: ${p => p.$open ? '32px' : '-510px'};
    z-index: ${p => p.$open ? '3400' : '300'};
    transition: ${p => p.$open ? 'top 1s ease, z-index 1.05s step-end' : 'top 1s ease, z-index step-end'};
    right: calc((100vw - min(1280px, 100vw)) / 2);
    background: transparent;
}
`

const StyledMoreMenu = styled.div.attrs({
    className: "more-menu"
})`
position:fixed;
right: 0;
text-align:left;
background-color:#FFF;
z-index: 3200;

box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

.menu-subtitle {
    color: #00000060;
}
`

const Overlay = styled.div<MenuContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 3150; /* Below the menu but above the content */
    display: ${p => (p.$open ? "block" : "none")};
    pointer-events: ${p => (p.$open ? "auto" : "none")};
`;

export function MoreMenu() {

    const app = useAppState()
    const events = app.events
    const user = useUserState()
    const seasonTeams = useSeasonsData()
    const { open: openIpadKeysModal } = useIpadApiKeys()
    const { open: openEditEmailNotificationsModal } = useEditEmailNotifications()
    const { open: openInviteUserModal } = useInviteUser()
    const { open: openLeaveTeamModal } = useLeaveTeam()

    const MenuContainer = app.layout.showMobile ? MobileMenuContainer : DesktopMenuContainer
    const [moreMenuOpen, setMoreMenuState] = useState(false)

    const toggleMoreMenu = useCallback(() => {
        moreMenuOpen ? app.menu.close() : app.menu.open()
        setMoreMenuState(!moreMenuOpen)
    }, [moreMenuOpen])

    const switchToDashboard = useCallback(async () => {
        toggleMoreMenu()
        const otp = await user.GetOtp()
        window.location.href = `${app.config.apps.leagueDashboard}/associations?otp=${otp}`
    }, [toggleMoreMenu, user])

    // register event listeners
    useEffect(() => events.add('close-more-menu', () => moreMenuOpen && setMoreMenuState(false)), [moreMenuOpen, setMoreMenuState])
    useEffect(() => events.add('toggle-more-menu', toggleMoreMenu), [toggleMoreMenu])
    useEffect(() => events.add('show-ipad-keys', openIpadKeysModal), [openIpadKeysModal])
    useEffect(() => events.add('show-edit-email-notifications', openEditEmailNotificationsModal), [openEditEmailNotificationsModal])
    useEffect(() => events.add('show-invite-user', openInviteUserModal), [openInviteUserModal])
    useEffect(() => events.add('show-leave-team', openLeaveTeamModal), [openLeaveTeamModal])

    // unleash
    const isTeamMembersReleased = useFlag('GuestAccess-LoggedIn')

    const InviteToTeam = useCallback(() => {
        
        if (isTeamMembersReleased) {
        
            return <>
                <Menu.Item label="Invite Team Staff" onClick={(e) => {
                    toggleMoreMenu()
                    alert("Invite Team Staff")
                }} />
                <Menu.Item label="Invite Parents / Players" onClick={(e) => {
                    toggleMoreMenu()
                    alert("Invite Parents / Players")
                }} />
            </>
        
        }

        // default to old invite to team
        return <Menu.Item label="Invite to Team" onClick={(e) => {
            toggleMoreMenu()
            events.handler('show-invite-user')(e)
        }} />

    }, [ isTeamMembersReleased ])

    return (<>

        <Overlay $open={moreMenuOpen} onClick={events.handler('close-more-menu')} />
        <MenuContainer $open={moreMenuOpen}>
            <StyledMoreMenu>
                <Menu.Container>
                    <Menu.Section label="">
                        <Menu.Item label="View Scoring Access Keys" onClick={(e) => {
                            toggleMoreMenu()
                            events.handler('show-ipad-keys')(e)
                        }} />
                        <InviteToTeam />
                        <Menu.Item label="Order Player Cards" onClick={() => {
                            toggleMoreMenu()
                            window.open("https://playercards.store/","player_cards")
                        }} />
                    </Menu.Section>
                    <Menu.Section label="My Account">
                        <Menu.Item label="Edit Profile" onClick={app.navigateOnClick("myaccount/profile")} />
                        <Menu.Item label="Email Notifications" onClick={(e)=> {
                            toggleMoreMenu()
                            events.handler('show-edit-email-notifications')(e)
                        }} />
                        <Menu.Item label="Accept Invitation" onClick={app.navigateOnClick("invitation")} />
                        <Menu.Item label="Log Out" onClick={() => {
                            toggleMoreMenu()
                            user.SignOut()
                        }} />
                    </Menu.Section>
                    <Menu.Section label="">
                        {seasonTeams?.dashboard && <Menu.Item label="Switch to League Dashboard" onClick={switchToDashboard} />}
                        <Menu.Item label="Help & Support" onClick={() => {
                            toggleMoreMenu()
                            window.open("https://help.gamesheet.app/", "helpscout")
                        }} />
                    </Menu.Section>
                    <Menu.Section label="">
                        <Menu.Item label="Leave Team" onClick={(e) => {
                            toggleMoreMenu()
                            events.handler('show-leave-team')(e)
                        }} />
                    </Menu.Section>
                </Menu.Container>
            </StyledMoreMenu>
        </MenuContainer>
    </>)

}