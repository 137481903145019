import { useAppState } from "@/state/app/useAppState";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export type Size = "lg" | "sm";

const Button = styled.a`
text-align: center;

&#btn-profile {
     min-width: 60px;
     max-width: 60px;
}
`

const NavIcon = styled.div<{ $size: Size }>`
display: flex;
align-items: center;
justify-content: center;

svg {
    height: ${p => p.$size == "sm" ? "24px" : "36px"};
    width: auto;
}
`

const NavText = styled.div<{ $size: Size }>`
display: flex;
align-items: center;
justify-content: center;
font-size: ${p => p.$size == "sm" ? "0.8rem" : "1rem"};
`

export type NavigationItem = {
    to?: string;
    action?: React.MouseEventHandler<HTMLAnchorElement>;
    label?: string;
    icon?: JSX.Element;
    desktop?: boolean;
    mobile?: 'left' | 'right' | 'icon';
    combined?: boolean;
}

const getUrl = ( url: string, urlHistory: string[] ) => {

    // If you're already on the roster tab but looking (for instance) at a specific player,
    // clicking the roster tab should take you to the default page for the roster tab.
    if (urlHistory[urlHistory.length - 1].startsWith(url)) return url;

    // If we're going to a new tab, we should try to go back to the page we were on when we last visited that tab.
    for (let i = urlHistory.length - 1; i >= 0; i--) {
        if (urlHistory[i].startsWith(url)) {
          return urlHistory[i];
        }
    }
    return url;

}

export function NavButton({ button, size="sm" }: {button : NavigationItem, size?: Size }) {

    const app = useAppState();
    const _to = button.to ? getUrl(button.to, app.urlHistory) : undefined;

    const content = <>
        <NavIcon $size={size}>{button.icon}</NavIcon>
        <NavText $size={size}>{button.label}</NavText>
    </>

    return <>
        {_to && !button.combined && (
            <NavLink to={_to}>
                {content}
            </NavLink>
        )}
        {button.action && !button.combined && (
            <Button onClick={button.action}>
                {content}
            </Button>
        )}
        {button.action && _to && button.combined && (
            <Button onClick={button.action}>
                <NavLink to={_to}>
                    {content}
                </NavLink>
            </Button>
        )}
    </>

}