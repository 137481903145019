import { atom, useRecoilState, useRecoilValue } from "recoil"
import { firebaseUserState, useSetFirebaseAuth } from "./useSetFirebaseAuth";
import { useLoginWithOtp } from "./useLoginWithOtp";
import { useSetGameSheetUserState } from "./useGameSheetUserState";
import { getOtp } from "./getOtp";
import config from "@/config";
import { useAppState } from "../app/useAppState";
import dayjs from "@/libs/dayjs";
import { useUserService } from "@/services/useUserService";
import { useCallback, useEffect } from "react";
import { useUnleashContext } from "@unleash/proxy-client-react";

export const userState = atom({
    key: 'userState',
    default: {
        tokens: {
            access: "",
            refresh: "",
            roles: "",
        }
    },
})

export type ExtraUserDetails = {
    claims: {
        title: string;
        level: {
            id: string;
            type: string;
        }
    }[],
    email: string;
    firstName: string;
    fuid: string;
    id: number;
    lastName: string;
    maintenance: {
        refreshTeamManagerRoles: boolean;
    },
    resetCode: string;
}

export const extraUserDetailsState = atom<ExtraUserDetails>({
    key: 'extraUserDetailsState',
    default: {
        claims: [],
        email: "",
        firstName: "",
        fuid: "",
        id: 0,
        lastName: "",
        maintenance: {
            refreshTeamManagerRoles: false,
        },
        resetCode: "",
    },
})

export type UserType = {
    tokens: {
        access: string;
        refresh: string;
        roles: string;
    }
}

export function useSetUserState(){

    useSetFirebaseAuth()
    useLoginWithOtp()
    useSetGameSheetUserState()

}

export type UserState = ReturnType<typeof useUserState>
export function useUserState() {

    const app = useAppState()
    const events = app.events
    const firebaseUser = useRecoilValue(firebaseUserState)
    const [user, setUser] = useRecoilState(userState)
    const [extraDetails, setExtraDetails] = useRecoilState(extraUserDetailsState);
    const { UpdatePassword, UpdateUser, SignIn, SignOut, GetUserEmailSubscriptions, GetClaims, CheckPermissions, GetLineupSubscriptions, CheckPermissionsForSeasonTeam } = useUserService(user, firebaseUser)
    const updateContext = useUnleashContext();

    const clearTokens = useCallback(() => {
        setUser(prev => ({
            ...prev,
            tokens: {
                access: "",
                refresh: "",
                roles: "",
            }
        }));
    }, [setUser]);

    const Expire = useCallback(async () => {
        try {
            const response = await fetch(`${config.gateways.auth}/auth/v4/expire-user`, { 
                method: "POST", 
                headers: { 
                    Authorization: `Bearer ${user.tokens.access}` 
                } 
            });
            clearTokens(); // Clear tokens after expiring and before refreshing
            return response;
        } catch (error) {
            clearTokens();
            throw error;
        }
    }, [user.tokens.access, clearTokens]);

    const updateTokens = useCallback(async (newTokens: { access: string; refresh: string; roles?: string }) => {
        setUser(prev => ({
            ...prev,
            tokens: {
                ...prev.tokens,
                access: newTokens.access,
                refresh: newTokens.refresh,
                ...(newTokens.roles && { roles: newTokens.roles })
            }
        }));
    }, [setUser]);

    useEffect(() => {
        app.setUnleashContext("userId", firebaseUser?.email || "", true)
    }, [firebaseUser?.email]);

    return {
        id: firebaseUser?.uid,
        displayName: firebaseUser?.displayName,
        email: firebaseUser?.email,
        photoURL: firebaseUser?.photoURL,
        tokens: user.tokens,
        isLoggedIn: !!firebaseUser,
        isRecentSignup: !!firebaseUser && dayjs().diff(dayjs(firebaseUser?.metadata.creationTime), "seconds") < 60,
        extraDetails,

        SignIn,
        SignOut,
        updateTokens,
        clearTokens,
        
        GetToken: () => {
            return user.tokens.access
        },
        
        GetOtp: async () => {
            if (user.tokens.access) {
                return getOtp(user.tokens.access);
            }
            return "";
        },
    
        GetExtraDetails: async () => {
            if (!extraDetails.id && user.tokens.access) {
                const response = await fetch(`${config.gateways.auth}/auth/v4/user`, { 
                    method: "GET",
                    headers: { 
                        Authorization: `Bearer ${user.tokens.access}` 
                    } 
                })
                if (response.ok) {
                    const data = await response.json();
                    setExtraDetails(data.user);
                    return data.user as ExtraUserDetails;
                }
            }

            return extraDetails;
        },

        UpdatePassword,
        UpdateUser,
        GetUserEmailSubscriptions,
        GetLineupSubscriptions,
        Expire,
        GetClaims,
        CheckPermissions,
        CheckPermissionsForSeasonTeam
    }

}