import { Title } from "@gamesheet/ui";
import { InsertInvitationCode } from "./step.InsertInvitationCode";
import { useAcceptInvitationFlow } from "./state/useAcceptInvitationFlow";
import { PageLoading } from "@/components/pure/PageLoading";
import { PreviewTeam } from "./step.PreviewTeam";
import { PreviewMerge } from "./step.PreviewMerge";
import { PrototeamOptions } from "./step.PrototeamOptions";
import { AcceptInvitationFlowPath } from "./types";
import { styled } from "styled-components";
import { useAppState } from "@/state/app/useAppState";
import { RegisterNewAccount } from "./step.RegisterNewAccount";
import useQueryParams from "@/hooks/useQueryParams";
import { useCallback, useEffect, startTransition, useMemo } from "react";
import { SchedulerPreview } from "./step.SchedulerPreview";
import { useInvitationCodeState } from "./state/useInvitationCodeState";

const MobileAcceptInvitationFlow = styled.div``

const DesktopAcceptInvitationFlow = styled.div`
max-width: 500px;
margin-left: auto;
margin-right: auto;
`

type Props = {
    path: AcceptInvitationFlowPath
    onDone?: () => void
}

export function AcceptInvitationFlow({ path }: Props) {

    const app = useAppState()
    // const StyledAcceptInvitationFlow = (app.layout.showTablet || app.layout.showDesktop) ? DesktopAcceptInvitationFlow : MobileAcceptInvitationFlow
    const StyledAcceptInvitationFlow = useMemo(() => 
        (app.layout.showTablet || app.layout.showDesktop) 
            ? DesktopAcceptInvitationFlow 
            : MobileAcceptInvitationFlow,
        [app.layout.showTablet, app.layout.showDesktop]
    );
    const flow = useAcceptInvitationFlow(path);
    const queryParams = useQueryParams();
    const [code, _setCode] = useInvitationCodeState();

    // const handleSchedulerBack = useCallback(() => {
    //     flow.controls.to(0)
    // }, [JSON.stringify(flow)])

    const handleSchedulerBack = useCallback(() => {
        flow.controls.to(0)
    }, [flow.controls]);

    // useEffect(() => {
    //     const code = queryParams['invitation']
    //     if (code) {
    //         flow.readInvitationCode(code)
    //         _setCode(code)
    //     }
    // },[ JSON.stringify(queryParams) ])

    useEffect(() => {
        const invitationCode = queryParams['invitation'];
        if (invitationCode && invitationCode !== code) {
            flow.readInvitationCode(invitationCode);
            _setCode(invitationCode);
        }
    }, [queryParams['invitation'], code, flow.readInvitationCode, _setCode]);

    if (flow.isLoading) {
        return <PageLoading />
    }

    return <StyledAcceptInvitationFlow>

        {/* Insert Invitation Code */}
        {flow.showCodeInput && <InsertInvitationCode
            onSubmit={flow.readInvitationCode}
            error={flow.error}
        />}

        {/* Show Team Preview */}
        {flow.showTeamPreview && flow.sampleTeam && <PreviewTeam
            logo={flow.sampleTeam.logo}
            title={flow.sampleTeam.title}
            season={flow.sampleTeam.season}
            division={flow.sampleTeam.division}
            onBack={flow.controls.prev}
            onAccept={flow.confirmCodeCorrect}
            roster={{
                players: flow.sampleTeam.roster,
                skaters: flow.sampleTeam.roster,
                coaches: [],
                goalies: [],
            }}
        />}

        {/* Show Scheduler Preview */}
        { flow.showSchedulerPreview && <SchedulerPreview
            prototeam_id={flow.sampleTeam?.prototeam_id || ""}
            inviteRoles={flow.invitation}
            onBack={handleSchedulerBack}
            onAccept={flow.acceptInvitationCode}
        />}

        {flow.showAccountRegistration && <RegisterNewAccount
            onBack={flow.controls.prev}
            onAccept={flow.registerNewAccount}
            error={flow.error}
        />}

        {flow.showPrototeamOptions && flow.sampleTeam && <PrototeamOptions
            title={flow.sampleTeam.title}
            season={flow.sampleTeam.season}
            onBack={flow.controls.prev}
            onAccept={flow.strategy === "add" ? flow.acceptInvitationCode : flow.previewMerge}
            error={flow.mergeError}
        />}

        {flow.showSelectedOptionSummary && flow.mergedPreview && <PreviewMerge
            logo={flow.mergedPreview.logo}
            title={flow.mergedPreview.title}
            prototeam={flow.mergedPreview.prototeam}
            season={flow.mergedPreview.season}
            division={flow.mergedPreview.division}
            onBack={flow.controls.prev}
            onAccept={flow.acceptInvitationCode}
            roster={{
                players: flow.mergedPreview.roster,
                coaches: [],
                goalies: [],
                skaters: flow.mergedPreview.roster
            }}
            error={flow.mergeError}
        />}

    </StyledAcceptInvitationFlow>

}