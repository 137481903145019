import logoPlaceholder from "@/assets/images/blank_jersey.svg"
import { Button, FormWrapper, Title } from "@gamesheet/ui";
import { useCallback, useEffect, useMemo } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import { useTeamsData } from "@/state/data/useTeams";
import { Menu, DropdownMenu } from "@gamesheet/ui"
import { InsetTextBlock } from "./components/InsetTextBlock";
import { PrototeamOption } from "./components/PrototeamOption";
import { usePrototeamOptionsState } from "./state/usePrototeamOptionsState";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { Strategy } from "./types";
import { Variant } from "@gamesheet/ui/build/shared.types";

const DropdownContainer = styled(InsetTextBlock)`

.dd-container {
    width: 100%;

    .button {
        text-align: left;

        svg {
            float: right;
        }
    }

    .dd-menu {
        left: 0;
        max-height: 250px;

        .menu {
            width: 100%;
            max-width: 100%;
            max-height: calc(250px - 0.1rem);
            overflow-y: auto;

            .menu-item {
                position: relative;
                padding-left: 73px;

                img {
                    position: absolute;
                    top: 4px;
                    left: 12px;
                    width: 48px;
                    height: 48px;
                    object-fit: contain;
                }
            }
        }
    }
}`

type PrototeamOptionsProps = {
    title: string;
    season: {
        title: string;
        id: number;
    },
    onAccept: () => void;
    onBack: () => void;
    error: string;
}

export function PrototeamOptions({ title, season, onBack, onAccept, error }: PrototeamOptionsProps) {

    const app = useAppState()
    const { teams, getPlaysIn, getSeasonIds } = useTeamsData()
    const selectableTeams = useMemo(() => 
        teams.filter(team => !getSeasonIds(team.id).includes(season.id)),
        [teams, getSeasonIds, season.id]
    );
    const { options: { strategy, linkedTeam }, setStrategy, setLinkedTeam } = usePrototeamOptionsState();
    const isLinkSelected = strategy === "link";
    const dropdownState = isLinkSelected ? undefined : "disabled";
    const isAcceptDisabled = (isLinkSelected && !linkedTeam) || (strategy === "default");
    const variant = isAcceptDisabled ? "muted" : "";
    const nextText = isLinkSelected ? "Continue" : 
    (app.layout.showTablet || app.layout.showDesktop) ? "Accept Invitation" : "Accept";

    const errorMessage = error.includes("stats years") ? "You cannot link two teams that are in different stats years. Please select a different team to link with or continue without linking." : "Failed to retrieve linked roster preview."
    
    const onClickAccept = useCallback(() => {
        if (!isAcceptDisabled) onAccept()
    }, [onAccept, isAcceptDisabled])

    const handleBack = useCallback(() => {
        onBack();
    }, [onBack]);

    const handleStrategyChange = useCallback((newStrategy: string) => {
        if (newStrategy === 'link' || newStrategy === 'add' || newStrategy === 'default') {
            const strategyValue = newStrategy as Strategy;
            if (strategyValue !== strategy) {
                setStrategy(strategyValue);
            }
        }
    }, [strategy, setStrategy]);

    // seperate out the button rendering logic to a separate effect
    // this is to prevent the buttons from being rendered on every render 
    useEffect(() => {
        if (!app.layout.showMobile) return;
    
        const buttonProps = {
            variant,
            isAcceptDisabled,
            nextText
        };
    
        const updateButtons = () => {
            const backButton = <Button type="button" size="lg" onClick={handleBack}>Back</Button>;
            const nextButton = (
                <Button 
                    type="button" 
                    size="lg" 
                    variant={buttonProps.variant as Variant} 
                    disabled={buttonProps.isAcceptDisabled} 
                    onClick={onClickAccept}
                >
                    {buttonProps.nextText}
                </Button>
            );
    
            app.ui('leftTray').set(backButton);
            app.ui('rightTray').set(nextButton);
        };
    
        updateButtons();
    
        return () => {
            app.ui('leftTray').clear();
            app.ui('rightTray').clear();
        };
    }, [app.layout.showMobile]); 
    
    useEffect(() => {
        if (!app.layout.showMobile) return;
        
        const backButton = <Button type="button" size="lg" onClick={handleBack}>Back</Button>;
        const nextButton = (
            <Button 
                type="button" 
                size="lg" 
                variant={variant} 
                disabled={isAcceptDisabled} 
                onClick={onClickAccept}
            >
                {nextText}
            </Button>
        );
    
        app.ui('leftTray').set(backButton);
        app.ui('rightTray').set(nextButton);
    }, [variant, isAcceptDisabled, nextText]);
    
    const renderButtons = app.layout.showTablet || app.layout.showDesktop ? (
        <>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="button" size="lg" onClick={handleBack}>Back</Button>
                <Button 
                    type="button" 
                    size="lg" 
                    variant={variant} 
                    disabled={isAcceptDisabled} 
                    onClick={onClickAccept}
                >
                    {nextText}
                </Button>
            </div>
        </>
    ) : null;

    return <div>

        <Title bartype="plain" text="" size="h5">
            <InsetTextBlock>How would you like to add {title} to your account?</InsetTextBlock>
        </Title>

        <FormWrapper>

            { selectableTeams.length !== 0 && <PrototeamOption
                id="link"
                selectedId={strategy}
                onClick={handleStrategyChange}
                name="mergeTeamOption"
                text={`Link ${title} to an existing team`}
            /> }
            { selectableTeams.length !== 0 && <DropdownContainer>
                <DropdownMenu
                    disabled={!isLinkSelected}
                    label={linkedTeam?.title || "Select Team"}
                    variant="inverted"
                    state={dropdownState}
                    style={{ display: 'block', width: '100%' }}
                >
                    {selectableTeams.map(team => <Menu.Item key={team.id} label={team.title} onClick={() => setLinkedTeam(team)}>
                        <img src={team.logo ? `${team.logo}/256` : logoPlaceholder} />
                        Plays in {getPlaysIn(team.id)}
                    </Menu.Item>)}
                </DropdownMenu>
            </DropdownContainer> }
            { selectableTeams.length !== 0 && <InsetTextBlock style={{ fontSize: "0.85rem", fontWeight: "500", lineHeight: ".9rem", marginBottom: "2.5rem", marginTop: "-10px" }}>
                <p>Select this option to link {title} to an existing team and merge the rosters.</p>
            </InsetTextBlock> }

            <PrototeamOption
                id="add"
                selectedId={strategy}
                onClick={handleStrategyChange}
                name="mergeTeamOption"
                text={`Add ${title} without linking to an existing team`}
            />
            <InsetTextBlock style={{ fontSize: "0.85rem", fontWeight: "500", lineHeight: ".9rem", marginTop: "5px" }}>
                Select this option to add {title} as a new team. They will appear as a new team
                in your dashboard with the logo and roster shown on the previous page.{" "}
                <a href="https://help.gamesheet.app/article/50-accepting-an-invitation-code-as-an-existing-user">More Info</a>
            </InsetTextBlock>

        </FormWrapper>

        { error !== "" && <ErrorCard title={"Cannot link these teams"} message={errorMessage} /> }

        {renderButtons}

    </div>

}