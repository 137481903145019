import { useRecoilState } from "recoil";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { useCallback, useRef } from "react";
import { PrototeamOptions, Strategy } from "../types";
import { TeamData } from "@/state/data/useTeams";

export function usePrototeamOptionsState() {
    const [state, setState] = useRecoilState(AcceptInvitationFlowState);
    const previousStrategy = useRef<Strategy>(state.prototeamOptions.strategy);
    
    const setStrategy = useCallback((strat: Strategy) => {
        if (strat !== previousStrategy.current) {
            previousStrategy.current = strat;
            setState(s => ({ 
                ...s, 
                prototeamOptions: { 
                    ...s.prototeamOptions, 
                    strategy: strat,
                    ...(strat !== 'link' && { linkedTeam: undefined })
                } 
            }));
        }
    }, [setState]);

    const setLinkedTeam = useCallback((team: TeamData) => {
        setState(s => ({ 
            ...s, 
            prototeamOptions: { 
                ...s.prototeamOptions, 
                linkedTeam: team 
            } 
        }));
    }, [setState]);

    return {
        options: state.prototeamOptions,
        setStrategy,
        setLinkedTeam,
    };
}