import { AcceptInvitationFlow as LegacyAcceptInvitationFlow } from "@/flows/AcceptInvitationFlow";
import { AcceptInvitationFlowPath } from "@/flows/AcceptInvitationFlow/types";
import { Title, Button } from "@gamesheet/ui";
import { useUserState } from "@/state/user/useUserState";
import { useAppState } from "@/state/app/useAppState"
import styled from 'styled-components';
import { useFlag } from "@unleash/proxy-client-react";
import { AcceptInvitationFlow } from "@/flows/GuestAccess";

const StyledSignupStep = styled.div.attrs({
    className: "signup-step"
})`
background-color: #fff;
border-radius: 8px;
padding: 16px;
min-height: 500px;
width: 100%;
`

export function InvitationPage() {

    const app = useAppState();
    const user = useUserState();
    const isTeamMembersReleased = useFlag("GuestAccess-LoggedIn");

    return <StyledSignupStep>
        <Title bartype="divider" text="Accept Invitation" size="h4" />
        { !isTeamMembersReleased && <LegacyAcceptInvitationFlow path={AcceptInvitationFlowPath.ExistingUser} onDone={() => { window.location.href = "/"; }} /> }
        { isTeamMembersReleased && <AcceptInvitationFlow /> }
    </ StyledSignupStep>
}