import { Button, Title } from "@gamesheet/ui";
import { useEffect, useMemo } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import { MobileRoster as RosterView } from "@/components/views/Roster/MobileRoster";
import { LayoutGridContainer } from "@/layouts/GridItems";
import { InsetTextBlock } from "./components/InsetTextBlock";
import { DetailedTeamPreview } from "@/components/pure/DetailedTeamPreview";
import { RosterWrapper } from "./components/RosterWrapper";
import { TeamData } from "@/state/data/useTeams";
import { Roster } from "@/state/data/useTeamRoster/types";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";

const DesktopTeamPreview = styled.div`
    .team-selector {
        padding-top: 0;

        .team-logo {
            padding-left: 8px;
            img {
                width: 96px;
                height: 96px;
            }
        }

        .team-title {
            .title {
                font-size: 1.75rem;
            }
            .subtitle {
                font-size: 1.1rem;
            }
        }
    }
`

const MobileTeamPreview = styled.div`
    .team-selector {
        margin-top: 15px;
        margin-bottom: 15px;

        .team-logo {
            padding-left: 8px;
        }
    }
`

type PreviewMergeProps = {
    logo?: string;
    title?: string;
    prototeam: TeamData;
    roster?: Roster;
    season?: {
        title: string;
    },
    division?: {
        title: string;
    },
    onAccept: () => void;
    onBack: () => void;
    error?: string;
}

export function PreviewMerge({ 
    logo = "", 
    title = "", 
    prototeam,
    season = { title: "" },
    division = { title: "" },
    onBack,
    onAccept,
    roster = {
        players: [],
        coaches: [],
        skaters: [],
        goalies: []
    },
    error = ""
}: PreviewMergeProps) {
    const app = useAppState()
    const StyledTeamPreview = (app.layout.showTablet || app.layout.showDesktop) ? DesktopTeamPreview : MobileTeamPreview;

    // Create buttons but don't manage tray directly
    const BtnBack = useMemo(() => <Button type="button" size="lg" onClick={onBack}>Back</Button>, [onBack])
    const BtnNext = useMemo(() => <Button type="button" size="lg" onClick={onAccept}>Accept Invitation</Button>, [onAccept])

    useEffect(() => {
        const setupMobileNav = () => {
            if (app.layout.showMobile) {
                app.ui('leftTray').set(BtnBack)
                app.ui('rightTray').set(BtnNext)
            }
        }

        setupMobileNav()

        // Return cleanup function
        return () => {
            if (app.layout.showMobile) {
                app.ui('leftTray').clear()
                app.ui('rightTray').clear()
            }
        }
    }, [app.layout.showMobile]) // Only re-run if mobile state changes

    // If we're not in mobile view, ensure trays are clear
    useEffect(() => {
        if (!app.layout.showMobile) {
            app.ui('leftTray').clear()
            app.ui('rightTray').clear()
        }
    }, [app.layout.showMobile])


    // Guard against prototeam being undefined
    if (!prototeam) {
        return <ErrorCard title="Error" message="Team data is missing" />
    }

    return (
        <div>
            <Title bartype="plain" text="" size="h3">
                <InsetTextBlock>You are linking {title} with {prototeam.title}</InsetTextBlock>
            </Title>

            <InsetTextBlock style={{fontSize: "0.85rem", fontWeight: "500", lineHeight: ".9rem"}}>
                These rosters will be combined into one and any future roster changes will affect both rosters.
            </InsetTextBlock>

            <StyledTeamPreview>
                <DetailedTeamPreview
                    onClick={() => {}}
                    logo={logo}
                    title={title}
                    season={season.title}
                    division={division.title}
                    $stretch={app.layout.showMobile}
                />
            </StyledTeamPreview>

            {roster.players.length > 0 && (
                <>
                    <Title bartype="divider" text="Roster" size="h4"/>
                    <LayoutGridContainer style={{ marginBottom: 15 }}>
                        <RosterWrapper>
                            <RosterView roster={roster} showTitles={false} />
                        </RosterWrapper>
                    </LayoutGridContainer>
                </>
            )}

            {error && <ErrorCard title="Cannot link these teams" message={error} />}
            
            {/* For desktop/tablet view */}
            {(app.layout.showTablet || app.layout.showDesktop) && (
                <>
                    <hr/>
                    <div style={{ display:'flex', justifyContent:'space-between' }}>
                        {BtnBack}
                        {BtnNext}
                    </div>
                </>
            )}

            {/* Instead of managing trays directly, expose buttons for parent to handle */}
            {app.layout.showMobile && (
                <div style={{ display: 'none' }}>
                    <div id="left-tray-content">{BtnBack}</div>
                    <div id="right-tray-content">{BtnNext}</div>
                </div>
            )}
        </div>
    )
}