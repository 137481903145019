import { useAppState } from '@/state/app/useAppState';
import { useUserState } from '@/state/user/useUserState';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

interface LeaveTeamState {
  loading: boolean;
  isOpen: boolean;
  tokenRefreshComplete: boolean;
  error: string | null;
}

export const leaveTeamModalState = atom<LeaveTeamState>({
  key: 'leaveTeamModalState',
  default: {
    loading: false,
    isOpen: false,
    tokenRefreshComplete: true,
    error: null
  }
});

export function useLeaveTeam() {
  const app = useAppState();
  const user = useUserState();
  const [{ loading, isOpen, tokenRefreshComplete, error }, setState] = useRecoilState(leaveTeamModalState);

  const handleTokenRefresh = useCallback(async (currentRefreshToken: string): Promise<boolean> => {
    try {
      // Wait for backend propagation after expire
      await new Promise(resolve => setTimeout(resolve, 1000));

      const response = await fetch(`${app.config.gateways.auth}/auth/v4/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentRefreshToken}`
        }
      });

      if (!response.ok) {
        throw new Error(`Token refresh failed: ${response.status}`);
      }

      const newTokens = await response.json();
      
      // Wait before updating state to ensure proper order
      await new Promise(resolve => setTimeout(resolve, 500));
      await user.updateTokens(newTokens);
      
      return true;
    } catch (error) {
      console.error('Token refresh error:', error);
      return false;
    }
  }, [app.config.gateways.auth, user]);

  const onLeaveTeam = useCallback(async ({ id }: { id: string }) => {
    setState(prev => ({
      ...prev,
      loading: true,
      error: null,
      tokenRefreshComplete: false
    }));

    try {
      // Store current refresh token for refreshing token later
      const currentRefreshToken = user.tokens.refresh;
      // const url = `https://gateway-eventlog-rxj26aywsq-nn.a.run.app/v5/users/${user.id}/remove-prototeam`;
      const url =  `${app.config.gateways.eventLog}/v5/users/${user.id}/remove-prototeam`
      // Leave team
      const leaveResponse = await fetch(
        url,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${user.tokens.access}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            attributes: {
              event: 'remove-prototeam',
              schema: 'user-prototeam',
              version: 'v1',
            },
            data: {
              userId: user.id,
              prototeamId: id,
            },
          })
        }
      );

      if (!leaveResponse.ok) {
        throw new Error(`Failed to leave team: ${leaveResponse.status}`);
      }

      setState(prev => ({
        ...prev,
        isOpen: false,
        loading: false,
        error: null
      }));

      // Wait for leave team to propagate
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Step 2: Expire user session
      const expireResponse = await fetch(`${app.config.gateways.auth}/auth/v4/expire-user`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.tokens.access}`
        }
      });

      if (!expireResponse.ok) {
        throw new Error('Failed to expire user session');
      }

      // Clear tokens from state
      await user.clearTokens();

      // Wait after clearing tokens
      await new Promise(resolve => setTimeout(resolve, 500));

      // Refresh tokens with retry
      let refreshSuccess = false;
      for (let i = 0; i < 3; i++) {
        refreshSuccess = await handleTokenRefresh(currentRefreshToken);
        if (refreshSuccess) break;
        
        // Wait before retry
        if (!refreshSuccess && i < 2) {
          await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
        }
      }
      
      if (!refreshSuccess) {
        throw new Error('Failed to refresh tokens after multiple attempts');
      }

      // Final wait to ensure everything is propagated
      await new Promise(resolve => setTimeout(resolve, 1000));

      setState(prev => ({
        ...prev,
        tokenRefreshComplete: true,
      }));
    } catch (error) {
      console.error('Leave team error:', error);
      setState(prev => ({
        ...prev,
        loading: false,
        tokenRefreshComplete: false,
        error: error instanceof Error ? error.message : 'Unknown error occurred'
      }));
    }
  }, [user, setState, handleTokenRefresh]);

  const open = useCallback(() => {
    setState(prev => ({
      ...prev,
      isOpen: true,
      error: null
    }));
    document.body.style.overflow = 'hidden';
  }, [setState]);

  const close = useCallback(() => {
    setState(prev => ({
      ...prev,
      isOpen: false,
      error: null
    }));
    document.body.style.overflow = 'auto';
  }, [setState]);

  return {
    loading,
    open,
    close,
    isOpen,
    onLeaveTeam,
    tokenRefreshComplete,
    error
  };
}