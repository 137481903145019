import { tokenUtils } from "@/libs/tokens";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useCallback, useState } from "react";

type InvitationCodeServiceState = {
    isFetching: boolean,
    hasServerError: boolean,
    serverError: string,
    hasValidationError: boolean,
    validationError: string,
    onChange: number
    isWaitingForTokens: boolean
}

export function useInvitationCodeService() {

    // import app and user states
    const app = useAppState();
    const user = useUserState();

    // initial state
    const initialState = {
        isFetching: false,
        hasServerError: false,
        serverError: "",
        hasValidationError: false,
        validationError: "",
        onChange: 0,
        isWaitingForTokens: false
    }

    // import service state
    const [service, _setServiceState] = useState({ ...initialState });
    const setServiceState = (state: InvitationCodeServiceState) => _setServiceState({ ...state, onChange: Date.now() })

    const verifyTokenState = useCallback(async (): Promise<boolean> => {
        if (service.isFetching) return false;

        if (!user.isLoggedIn) return true;

        const hasValidTokens = tokenUtils.validateTokenState(user);
        if (!hasValidTokens) {
            setServiceState({
                ...service,
                isWaitingForTokens: true,
                hasValidationError: true,
                validationError: "Please wait for authentication to complete..."
            });
            
            const tokensValid = await tokenUtils.waitForValidTokens(user);
            setServiceState({
                ...service,
                isWaitingForTokens: false,
                hasValidationError: !tokensValid,
                validationError: tokensValid ? "" : "Authentication failed. Please try logging out and back in."
            });
            return tokensValid;
        }
        return true;
    }, [user, service]);


    // load code details
    const loadCodeDetails = useCallback(async (code: string) => {
        const tokensValid = await verifyTokenState();
        if (!tokensValid) return {
            status: "error",
            errors: [{ code: 401, message: "Authentication error. Please try again." }]
        };
        setServiceState({ ...service, isFetching: true })

        // conditionally set auth header
        const headers = new Headers()
        user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`)

        // send request
        const url = `${app.config.gateways.auth}/auth/v4/invitation-code/${code}`
        let response = await fetch(url, { headers }).then(res => res.json()).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((message: string) => message).join(),
            })
            return response;
        }

        // the payload should be changed to match the error format, but for now we'll just return the response
        // add the status property to the response for consistency and fault tolerance
        response = {
            status: response.status || "success",
            data: response.data || response
        }

        // set state
        setServiceState({
            ...service,
            isFetching: false,
            hasServerError: false,
            serverError: "",
        })

        return response

    }, [user.tokens.access, app.config.gateways.auth, service, verifyTokenState])

    // create account
    const createAccount = useCallback(async (code: string, firstName: string, lastName: string, email: string, password: string) => {

        setServiceState({ ...service, isFetching: true })

        // send request
        let response = await fetch(`/events/create-account`, {
            method: 'POST',
            body: JSON.stringify({ code, firstName, lastName, email, password })
        }).then(res => res.json()).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((e: { message: string }) => e.message).join(),
            })
            return response;
        }

        setServiceState({ ...service, isFetching: false })
        return {
            status: "success",
        }

    }, [
        app.config.gateways.auth,
        service
    ])

    const setFirebaseUserId = useCallback(async (email: string, uid: string) => {

        setServiceState({ ...service, isFetching: true })

        // send request
        let response = await fetch(`/events/set-user-fuid`, {
            method: 'POST',
            body: JSON.stringify({ email, uid })
        }).then(res => {
            return {
                status: "success",
                errors: [],
                data: {}
            }
        }).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((e: { message: string }) => e.message).join(),
            })
            return response;
        }

        setServiceState({ ...service, isFetching: false })
        return response

    }, [
        app.config.gateways.auth,
        service
    ])

    // final step, actually accepting the invitation
    const acceptInvitation = useCallback(async (code: string, strategy: string, teamId: number, prototeamId: string) => {
        const tokensValid = await verifyTokenState();
        if (!tokensValid) return {
            status: "error",
            errors: [{ code: 401, message: "Authentication error. Please try again." }]
        };
        setServiceState({ ...service, isFetching: true })

        // conditionally set auth header
        const headers = new Headers()
        user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`)

        // send request
        let response = await fetch(`/events/accept-invite`, {
            method: 'POST',
            body: JSON.stringify({ code, strategy, prototeamId, teamId }),
            headers
        }).then(res => res.json()).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((e: { message: string }) => e.message).join(),
            })
            return response;
        }

        setServiceState({ ...service, isFetching: false })

        return {
            status: "success",
        }

    }, [
        user.tokens.access,
        service,
        verifyTokenState
    ])

    // get preview of merging invite team with existing prototeam
    const loadMergePreview = useCallback(async (teamId: number, prototeamId: string) => {

        setServiceState({ ...service, isFetching: true })

        // conditionally set auth header
        const headers = new Headers({
            'Content-Type': 'application/json',
            ...(user.isLoggedIn ? { 
                'Authorization': `Bearer ${user.tokens.access}`
            } : {})
        })
        
        // send request
        const url = `${app.config.gateways.events}/prototeams`
        let response = await fetch(url, {
            method: 'POST',
            credentials: 'omit',
            body: JSON.stringify({
                event: "preview-shuffle-proto-team",
                attributes: {
                    schema: "preview-shuffle-team-event"
                },
                data: {
                    id: prototeamId,
                    seasonTeam: teamId
                }
            }),
            headers
        }).then(res => res.json()).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((e: { message: string }) => e.message).join(),
            })
            return response;
        }

        // the payload should be changed to match the error format, but for now we'll just return the response
        // add the status property to the response for consistency and fault tolerance
        response = {
            status: response.status || "success",
            data: response.data || response
        }

        // set state
        setServiceState({
            ...service,
            isFetching: false,
            hasServerError: false,
            serverError: "",
        })

        return response

    }, [
        user.tokens.access,
        app.config.gateways.events,
        service
    ])

    return {

        // data
        ...service,

        // functions
        loadCodeDetails,
        createAccount,
        setFirebaseUserId,
        acceptInvitation,
        loadMergePreview,
        reset: () => _setServiceState({ ...initialState }),
        isWaitingForTokens: service.isWaitingForTokens
    }

}