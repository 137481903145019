import { AccessTokenPayload, TokenResponse, RoleItem } from './../types/lib.d';
import { decodeToken, isExpired } from 'react-jwt';
import { UserState } from '@/state/user/useUserState';
import config from '@/config';
import { Resource, Claim, Level, Role, Levels } from '@gamesheet/tspkg-auth-client';

export const tokenUtils = {
  validateTokenState: (user: UserState): boolean => {
    return !!(user.tokens.access && user.tokens.refresh && user.tokens.roles);
  },

  waitForValidTokens: async (user: UserState, maxAttempts = 5): Promise<boolean> => {
    for (let i = 0; i < maxAttempts; i++) {
      if (tokenUtils.validateTokenState(user)) {
        return true;
      }
      // Wait 500ms (arbitrary value) before retrying
      await new Promise(resolve => setTimeout(resolve, 500));
    }
    return false;
  },

  decodeAccessToken: (token: string) => {
    if (!token) return null;
    
    try {
      const decodedToken = decodeToken<AccessTokenPayload>(token);
      const expired = isExpired(token);
      
      if (!decodedToken) return null;

      return {
        ...decodedToken,
        isExpired: expired,
        expiresAt: new Date(decodedToken.exp * 1000),
        issuedAt: new Date(decodedToken.iat * 1000)
      };
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  },

  expireToken: async (user: UserState): Promise<TokenResponse | null> => {
    try {
      const response = await fetch(`${config.gateways.auth}/auth/v4/expire-user`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.tokens.access}`
        }
      });
      return response.json();
    } catch (error) {
      console.error('Error expiring token:', error);
      return null;
    }
  },

  refreshToken: async (user: UserState): Promise<TokenResponse | null> => {
    try {
      if (!user.tokens.access || isExpired(user.tokens.access)) {
        return null;
      }

      const response = await fetch(`${config.gateways.auth}/auth/v4/refresh`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.tokens.access}`
        }
      });

      if (!response.ok) throw new Error('Failed to refresh token');

      const tokens: TokenResponse = await response.json();
      return tokens;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  },

  decodeClaims: (rolesToken: string): Claim[] => {
    try {
      const decoded: { roles?: RoleItem[] } = decodeToken(rolesToken) || {};
      
      return (decoded?.roles || []).map(roleItem => ({
        title: roleItem.title as Role,
        level: {
          type: roleItem.level.type,
          id: parseInt(roleItem.level.id, 10),
        },
      }));
    } catch (error) {
      console.error('Error decoding claims:', error);
      return [];
    }
  },

  // check if user has access to a specific team
  hasTeamAccess: (user: UserState, teamId: string): boolean => {
    try {
      const claims = tokenUtils.decodeClaims(user.tokens.roles);
      return claims.some(claim => 
        claim.level.type === Levels.TEAMS && claim.level.id.toString() === teamId
      );
    } catch (error) {
      console.error('Error checking team access:', error);
      return false;
    }
  },

  // get all team claims
  getTeamClaims: (user: UserState): Claim[] => {
    try {
      const claims = tokenUtils.decodeClaims(user.tokens.roles);
      console.log(claims);
      return claims.filter(claim => claim.level.type === Levels.TEAMS);
    } catch (error) {
      console.error('Error getting team claims:', error);
      return [];
    }
  },

  // Check if token is approaching expiration
  shouldRefresh: (token: string, thresholdMinutes: number = 5): boolean => {
    const decoded = tokenUtils.decodeAccessToken(token);
    if (!decoded) return true;
    
    const now = new Date();
    const expiresAt = decoded.expiresAt;
    const thresholdMs = thresholdMinutes * 60 * 1000;
    
    return expiresAt.getTime() - now.getTime() < thresholdMs;
  },

  // Get token expiration date
  getExpirationDate: (token: string): Date | null => {
    const decoded = tokenUtils.decodeAccessToken(token);
    return decoded?.expiresAt || null;
  },

  // Get user identity from token
  getUserIdentity: (token: string): string | null => {
    const decoded = tokenUtils.decodeAccessToken(token);
    return decoded?.identity || null;
  },

  // Check if token is expired
  isTokenExpired: (token: string): boolean => {
    return isExpired(token);
  }
};

export type { AccessTokenPayload, TokenResponse, Claim, Level };