import { useEffect } from "react"
import { useAppState } from "@/state/app/useAppState"
import { Logo } from "@gamesheet/ui"

import ScheduleIcon from '@mui/icons-material/DateRange';
import RosterIcon from '@mui/icons-material/Groups';
import StatsIcon from '@mui/icons-material/Scoreboard';
import MoreIcon from '@mui/icons-material/MoreHoriz'

import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { useReadTeamsData } from "@/state/data/useTeams";
import { useReadTeamEventsData } from "@/state/data/useTeamEvents";
import { LoadingLayout } from "@/layouts/LoadingLayout";

import { useUserState } from "@/state/user/useUserState";
import { useReadActiveRosterData } from "@/state/data/useTeamRoster/useReadActiveRosterData";
import { useUpdateService } from "@/services/useVersionService";
import { InvitationPage } from "@/pages/user.invitation";
import { IsolatedLayout } from "@/layouts/IsolatedLayout";
import PWAPrompt from "react-ios-pwa-prompt";

const DesktopIconBar = styled.div`
height: 32px;
background-color: ${props => props.theme.primaryColor};
padding: 4px;
position: fixed;
left: 0;
top: 0;
right: 0;
z-index: 400;

div {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    button {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${props => props.theme.textOnPrimaryColor};
        background-color: transparent;
        border: none;
    }
}
`

const StyledLogo = styled(Logo).attrs<{
    className?: string
}>({
    className: "logo"
})`
height: 24px;
margin: 5px;
padding: 5px;
`

export function TeamsApp() {

    const app = useAppState()
    const user = useUserState()
    const events = app.events

    // track url history
    useEffect(() => {

        if (app.urlHistory[app.urlHistory.length - 1] !== app.location.pathname) {
            app.setUrlHistory([...app.urlHistory, app.location.pathname])
        }

    }, [ app.urlHistory, app.location.pathname ]);

    useReadTeamsData()
    useReadTeamEventsData()
    useReadActiveRosterData()
    useUpdateService()

    const isTeamsLoadingComplete = app.loading.isComplete('teams');
    const showLoadingLayout = !isTeamsLoadingComplete || (app.teams.isUnderThreshold && user.isRecentSignup);
    const showTooFewTeams = app.teams.isUnderThreshold;

    useEffect(() => {

        app.navigation.set([
            { to: "/games", icon: <ScheduleIcon />, label: "Schedule", mobile: "left", desktop: true },
            { to: "/roster", icon: <RosterIcon />, label: "Roster", mobile: "left", desktop: true },
            { to: "/games", mobile: "icon" },
            { to: "/seasons", icon: <StatsIcon />, label: "Seasons", mobile: "right", desktop: true },
            { action: events.handler('toggle-more-menu'), icon: <MoreIcon />, label: "More", mobile: "right" },
        ])

    }, [])

    if (showLoadingLayout) {
        return <LoadingLayout />
    }

    if (showTooFewTeams) {
        return <IsolatedLayout>
            <InvitationPage />
        </IsolatedLayout>
    }

    return <>

        <Outlet key={app.lastChange} />
        { user.isLoggedIn && app.location.pathname === '/games' && <PWAPrompt timesToShow={100} promptOnVisit={1} appIconPath='/teamlogo.png' copyTitle='Install GameSheet Teams' copySubtitle='Manage Your Team’s Schedule, Roster, and Stats in One App' copyDescription='Install the GameSheet Teams app for full-screen mode, faster loading, push notifications, and quick access from your Home screen.' copyShareStep="Tap the 'Share' button on the menu bar below." copyAddToHomeScreenStep="Scroll down and tap the 'Add to Home Screen' option."  />}

    </>
}