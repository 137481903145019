import { Title } from "@gamesheet/ui";
import { useCallback } from "react";
import { styled } from "styled-components";

const StyledTitleRadio = styled.label`
    display: flex;
    align-items: center;
    gap: 15px;

    .form-check-label {
        flex: 1;
    }
`

type PrototeamOptionProps = {
    id: string
    selectedId: string;
    onClick: (id:string) => void;
    name: string;
    text: string;
}

export function PrototeamOption({ name, id, selectedId, onClick, text }:PrototeamOptionProps){

    const handleClick = useCallback((e: React.MouseEvent | React.ChangeEvent) => {
        onClick(id);
    }, [id, onClick]);

    const isSelected = id === selectedId; 

    return <Title bartype="bar" text="" size="h6" variant={isSelected ? "" : "muted"}>
        <StyledTitleRadio onClick={handleClick}>
            <input
                readOnly
                className="form-check-input" 
                type="radio" 
                checked={isSelected} 
                name={name} 
                id={id} 
            />
            <label className="form-check-label">
                {text}
            </label>
        </StyledTitleRadio>
    </Title>

}