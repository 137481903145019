import { styled } from "styled-components";
import { AcceptInvitationFlow } from "../AcceptInvitationFlow";
import { AcceptInvitationFlowPath } from "../AcceptInvitationFlow/types";
import { useEffect } from "react";
import { useAppState } from "@/state/app/useAppState";
import { Title } from "@gamesheet/ui";
import { useFlag } from "@unleash/proxy-client-react";

const StyledSignupStep = styled.div.attrs({
    className: "signup-step"
})`
background-color: #fff;
border-radius: 8px;
padding: 16px;
min-height: 500px;
width: 100%;
`

export function SignupStep(){
    
    const isTeamMembersReleased = useFlag("GuestAccess-NotLoggedIn");
    
    const app = useAppState()
    useEffect(() => {
        app.events.add("AcceptInvitationFlow.NewUser.Complete", () => {
            app.events.trigger("LoginFlow.SignupPath.Complete")
        })
    }, [])

    return <StyledSignupStep>
        <Title bartype="divider" text="Registration" size="h3"/>
        { isTeamMembersReleased && <p>Guest Access Accept Invitation Flow</p>}
        { !isTeamMembersReleased && <AcceptInvitationFlow path={AcceptInvitationFlowPath.NewUser} />}
    </StyledSignupStep>
}